import React, { Component } from 'react'
import { Row, Col, Card, Input, Select, Button, Radio, Alert } from 'antd'
import { injectIntl } from 'react-intl'

import LoginAPI from '../../../api/user'
import FamilyAPI from '../../../api/Family/index'
import { LoginOutlined } from '@ant-design/icons'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      account: null,
      password: null,
      loading: false,
      errorMessage: null,
      value: 1
    }
  }

  accountOnChange = (event) => {
    this.setState({ account: event.target.value })
  }

  passwordOnChange = (event) => {
    this.setState({ password: event.target.value })
  }

  onLoginRoleChange = (checked) => {
    this.setState({ value: checked.target.value })
  }

  userLogin = async () => {
    const result = await LoginAPI.login({
      account: this.state.account,
      password: this.state.password
    })

    if (result.status === 200) {
      this.props.onLoginSuccess({
        id: result.data.id,
        merchant: result.data.merchant,
        name: result.data.name,
        email: result.data.email,
        liveRoomIsBegin: result.data.liveRoomIsBegin,
        accessToken: result.data.token,
        isAdmin: false,
      })
    } else {
      throw result
    }
  }

  familyLogin = async () => {
    const result = await FamilyAPI.login({
      account: this.state.account,
      password: this.state.password
    })

    if (result.status === 200) {
      this.props.onLoginSuccess({
        id: result.data.id,
        familyId: result.data.familyId,
        merchant: result.data.merchant,
        name: result.data.name,
        email: result.data.email,
        accessToken: result.data.token,
        isAdmin: true,
      })
    } else {
      throw result
    }
  }

  loginSummit = async () => {
    if (!this.state.account || !this.state.password) return

    try {
      this.setState({ loading: true })

      if (this.state.value === 1) {
        await this.userLogin()
      } else {
        await this.familyLogin()
      }

      this.setState({ loading: false })
    } catch (error) {
      this.setState({ loading: false, errorMessage: error })
    }
  }

  render() {
    const { intl, language, onLanguageChange } = this.props
    const Option = Select.Option

    const SummitButton = (
      <Button size="small"
        icon={<LoginOutlined />}
        style={{ border: 'none', backgroundColor: 'transparent' }}
        disabled={this.state.loading || !this.state.account || !this.state.password}
        onClick={this.loginSummit}
      />
    )

    return (
      <div style={{ height: '100%' }}>
        <Card
          style={{ borderRadius: 10, maxWidth: 400, margin: "20% auto auto auto" }}
        >
          {
            process.env.REACT_APP_BUILD_TYPE !== 'SPORT'
            &&
            <Radio.Group
              onChange={this.onLoginRoleChange}
              defaultValue={this.state.value}
              buttonStyle="solid"
              style={{ marginBottom: 20 }}
            >
              <Radio.Button value={1}>{intl.formatMessage({ id: 'login.modelLogin' })}</Radio.Button>
              <Radio.Button value={2}>{intl.formatMessage({ id: 'login.familyLogin' })}</Radio.Button>
            </Radio.Group>
          }

          <Input
            addonBefore={intl.formatMessage({ id: 'login.account' })}
            value={this.state.account}
            placeholder={intl.formatMessage({ id: 'login.accountPlaceholder' })}
            style={{ marginBottom: 20 }}
            onChange={this.accountOnChange}
            onPressEnter={this.loginSummit}
          />
          <Input
            addonBefore={intl.formatMessage({ id: 'login.password' })}
            type="password"
            suffix={SummitButton}
            value={this.state.password}
            placeholder={intl.formatMessage({ id: 'login.password' })}
            style={{ marginBottom: 8 }}
            onChange={this.passwordOnChange}
            onPressEnter={this.loginSummit}
          />

          {
            this.state.errorMessage ?
              <Alert message={this.state.errorMessage} type="error" showIcon />
              : null
          }

          <Select
            style={{ float: 'right', marginTop: 8 }}
            defaultValue={language}
            onChange={onLanguageChange}
          >
            <Option value="zh-TW">繁體中文</Option>
            <Option value="zh-CN">简体中文</Option>
            <Option value="en">English</Option>
            <Option value="th">ไทย</Option>
          </Select>
        </Card>
      </div>
    )
  }
}

export default injectIntl(Login)
