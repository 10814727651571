import { i18nObject } from '../helper/helper'

const language = {
  app: {
    mobileLogin: 'Điện thoại đăng nhập',
    logout: 'Đăng xuất',
    qrcodeDesc: 'Mở ứng dụng quét mã QR đăng nhập'
  },
  familyMonitor: {
    anchor_count: 'Số lượng IDOL hiện tại',
    anchor_live_count: 'Số người livestream giải trí',
    anchor_porn_live_count: 'Số người livestream show',
    clientCount: 'Quan sát tổng số lượng IDOL live',
    no_anchor: 'Không IDOL LIVETRAM',
    sureRemove: 'Xác nhận kick khỏi ứng dụng?',
    cancel: 'Hủy bỏ',
    confirm: 'Xác nhận',
    remove: 'Kick khỏi ứng dụng',
    monitor_live: 'Giám sát hội viên',
    sendGift: 'Tặng cho IDOL',
    monitor: 'Người giám sát',
    enterRoom: 'Vào phòng',
    anchor_live: 'Livestream giải trí',
    anchor_porn_live: 'Livestream show',
    inputPlaceholder: 'Người giám sát đưa IDOL thông tin cá nhân...',
    send: 'Gửi đi',
    kick_success: 'Quan sát thành công cập nhật danh sách IDOL',
  },
  userInfo: {
    icon: 'Hình nền AVATA',
    backgroundImage: 'Phong nền ảnh',
    name: 'Tên IDOL',
    name_en: 'Tên tiếng anh IDOL',
    email: 'Gmail IDOL',
    password: 'Mật khẩu',
    birthday: 'Ngày sinh',
    height: 'Chiều cao',
    weight: 'Cân nặng',
    width: 'Số đo 3 vòng',
    blood: 'Nhóm máu',
    constellation: 'Cung hoàn đạo',
    language: 'Ngôn ngữ',
    education: 'Học lực',
    job: 'Kinh nghiệm công việc',
    interest: 'Sở thích',
    movie: 'Phim yêu thích',
    food: 'Món ăn yêu thích',
    actor: 'Thần tượng yêu thích',
    game: 'Trò chơi yêu thích',
    number: 'Số may mắn',
    introduction: 'Tự giới thiệu',
    modifyPassword: 'Thay đổi mật khẩu',
    modify: 'Thay đổi'
  },
  routes: {
    info: 'Tư liệu cá nhân',
    dashboard: 'Bảng điều khiển',
    liveControl: 'Quản lý IDOL',
    giftHistory: 'Lịch sử quà tặng ',
    liveHistory: 'lịch sử live',
    modelPlayer: 'Toàn bộ buổi live trực tiếp',
    RoomOrder: 'Kỷ luật phí phòng live',
    analysis: 'Phân tích số lượng phòng live',
    userIncome: 'Kỷ luật thu nhập IDOL',
    familyManager: 'Quản lý tộc',
    familyIncome: 'Kỷ luật thu nhập của tộc',
    events: 'Sự kiện thể thao',
    SportStatistics: '查询赛事开播统计',
    monitor_live: '监控直播'
  },
  global: {
    update: 'Cập nhật',
    query: 'Tra cứu',
    dateRange: 'Phạm vi ngày',
    all: 'Toàn bộ ',
    export: 'Xuất'
  },
  dateTimePicker: {
    today: 'Hôm nay',
    yesterday: 'Hôm qua',
    l7days: '7 ngày gần nhất',
    l30days: '30 ngày gần nhất',
    tMonth: 'Tháng này',
    lMonth: 'Tháng trước',
    custom: 'Khoảng thời gian tùy chỉnh',
  },
  lasSocket: {
    disconnect: 'Đã ngắt kết nối',
    connect: 'Đã kết nối',
    loginFailed: 'Đăng nhập thất bại'
  },
  login: {
    title: 'Idol đăng nhập',
    account: 'Tài khoản',
    accountPlaceholder: 'Email',
    password: 'Mật khẩu',
    modelLogin: 'Idol đăng nhập',
    adminLogin: 'Quản lí hội viên đăng nhập',
    familyLogin: 'Tộc đăng nhập',
    adminPlaceholder: 'Tài khoản quản trị viên',
  },
  giftHistory: {
    model: 'Tên idol',
    fromName: 'Tài khoản thưởng',
    giftInfo: 'Qùa',
    amount: 'Tổng số tiền',
    createdAt: 'Ngày',
    sum: 'Tổng thưởng'
  },
  liveHistory: {
    createdAt: 'Thời gian bắt đầu',
    updatedAt: 'Thời gian kết thúc',
    total: 'Tổng',
    type: 'Thể loại',
    all: 'Toàn bộ',
    type0: 'Phòng live',
    type1: 'Phát trực tuyến trên điện thoại di động'
  },
  liveAnalysis: {
    date: 'Ngày',
    liveCount: 'Số lần live',
    totalInterval: 'Thời gian mở live',
    watchedCounts: 'Số lượng xem',
    clientCounts: 'Số người xem',
    giftSum: 'Tiền quà',
    betCount: 'Số lượng người cược',
    winOrLose: 'Người chơi thắng / thua',
    eBet: 'Cược điện tử',
    eBetWin: 'Vé số điện tử',
    liveBet: 'Người cược thật',
    liveValidBet: 'Người cược thật có hiệu lực',
    liveWin: 'Người thật nhận thưởng',
    lotteryBet: 'Cược vé số',
    lotteryWin: 'Trúng thưởng vé số',
    battleLostSum: 'Cược bị thua',
    battleValidBet: 'Cược có hiệu lực',
    battleWinSum: 'Cược thắng',
    fishBet: 'Cược bắn cá',
    fishWin: 'Trúng thưởng bắn cá',
    sportBet: '体育投注',
    sportValidBet: '体育有效投注',
    sportWin: '体育中奖',
    handicapBet: 'Cá cược xổ số',
    handicapWin: 'Trúng thưởng xổ số',
    statisticTime: 'Tổng thời gian',
    model: 'Tên idol',
    privateFees: 'Phí phòng live',
    totalGiftSum: 'Thống kê tiền quà',
    totalPrivateFees: 'Thống kê phí phòng live',
  },
  chat: {
    gift: 'Tặng bạn nè',
    heart: 'Trao tặng trái tim',
    monitor: 'Người giám sát camera',
    model: 'Idol',
    placeholder: 'Nói gì đi...',
    betting: 'Đặt cược rồi',
    win: 'Trúng thưởng rồi',
    send: 'Tặng đi',
    enter: 'Đã vào phòng',
    focus: 'Đã theo dõi idol',
    manager: 'Quản lí phòng'
  },
  games: {
    series: '{series} Loạt thời gian',
    whichSeries: 'Thứ {series} loạt thời gian',
    lottery: 'Vé số',
    esport: '电竞',
    sport: '体育',
    littleGame: 'Trò chơi nhỏ',
    comingSoon: 'Vui lòng chờ đợi',
    gameSelected: 'Trò chơi hiện đang chọn',
    gameRecommend: 'Xác nhận gửi đề xuất',
    gameCancel: 'Hùy đề xuất',
  },
  liveControl: {
    scheduledAt: 'Thời gian mở live dự kiến',
    webStart: 'Phòng live mở',
    webEnd: 'Phòng live tắt',
    mobile: 'Ứng dụng điện thoại',
    PC: 'Trợ giúp OBS',
    PCStream: 'Công cụ phát trực tuyến tuyến trên máy tính',
    PCStreaming: 'Công cụ phát trực tuyến tuyến trên máy tính - đang phát trực tuyến',
    pushUrl: 'Trang wed trực tuyến',
    pushKey: 'Chìa khóa trực tuyến',
    OBSContent: 'Nhắc nhở : Sử dụng công cụ trợ giúp OSB , sau đó nhấp vào nút "bắt đầu" bên dưới',
    loginQRCode: 'Click để lấy mã QR đăng nhập',
    onAir: 'Bắt đầu trực tiếp live',
    selectDevice: 'Vui lòng chọn thiết bị phát sóng trực tiếp',
    normal: 'Quay lại vidieo của live',
    vip: 'Thể hiện',
    private: 'Phí riêng',
    onAirType: 'Thể loại phòng',
    roomLevel: 'Cấp độ vào phòng',
    roomWatch: 'Thời gian tham quan',
    roomAmount: 'Phí vào phòng',
    min: 'Điểm',
    unrestricted: 'Vô hạn',
    qrcodeMark: 'Mã QR có hiệu lực trong vòng 5 phút, vui lòng lấy lại khi hết hạn'
  },
  RoomOrder: {
    customerName: 'Tên người dùng',
    createdAt: 'Thời gian giao dịch',
    fee: 'Tiền ảo',
    minute: 'Số giờ (phút)',
  },
  UserIncome: {
    Date: 'Ngày tháng',
    onairDuration: 'Thời gian phát live',
    Wage: 'Lương theo giờ',
    giftSplite: 'Phần trăm quà tặng',
    roomFeeSplite: 'Phần trăm phí phòng live',
    giftIncome: 'Phần quà thu nhập',
    roomFeeIncome: 'Thu nhập phí phòng live',
    onairIncome: 'Thu nhập phát sóng',
    totalGiftIncome: 'Thu nhập từ quà tặng',
    totalRoomFeeIncome: 'Thu nhập từ phí vào phòng',
    totalIncome: 'Tóm tắt thu nhập'
  },
  FamilyIncome: {
    Date: 'Ngày tháng',
    users: 'Số lượng IDOL LIVE',
    Wage: 'Lương theo giờ',
    giftSplite: 'Phần trăm quà tặng(%)',
    roomFeeSplite: 'Phần trăm phí phòng live(%)',
    onairDuration: 'Thời gian phát live ',
    Income: 'lợi nhuận của tộc',
    amount: 'Số tiền thanh toán',
    amount_na: 'Số tiền chưa thanh toán',
    endDate: 'Thời gian thanh toán',
    option: 'Thao tác',
    memberProfit: 'Chi tiêu thu nhập của IDOL',
    detail: 'Chi tiết về IDOL',
    sum: 'Thống kê'
  },
  UserDetails: {
    userId: 'ID của IDOL',
    familyName: 'Tên của tộc',
    userName: 'Tên IDOL',
    Wage: 'Lương theo giờ',
    giftSplite: 'Phần trăm quà tặng(%)',
    roomFeeSplite: 'Phần trăm phí phòng live(%)',
    onairDuration: 'Thời gian phát live',
    giftIncome: 'Phần quà thu nhập',
    roomFeeIncome: 'Thu nhập phí phòng live',
    onairIncome: 'Thu nhập phát sóng',
    totalGiftIncome: 'Thu nhập từ quà tặng',
    totalRoomFeeIncome: 'Thu nhập từ phí vào phòng',
    totalIncome: 'Thu nhập phát sóng',

    total: 'Thống kê',
  },
  PopModifyPassword: {
    modifyPassword: 'Thay đổi mật khẩu',
    cancel: 'Loại bỏ',
    newPassword: 'Nhập mật khẩu mới',
    chackPassword: 'Nhập lại mật khẩu mới',
    success: 'Thay đổi thành công',

    chackFailure: 'Mật khẩu nhập lại lần 2 không khớp',
    inputPassword: 'Nhập 6~10 số bao gồm một ký tự tiếng anh'
  },

  LineChart: {
    date: 'Ngày tháng',
    points: 'Điểm',
  },

  FamilyInfo: {
    modelName: 'Tên IDOL',
    modelEmail: 'EMAIL IDOL',
    hourlyRate: 'Lương theo giờ',
    giftAmountRate: 'Phần trăm quà tặng (%)',
    privateRoomFeeRate: 'Phần trăm phí phòng live (%)',
    popular: 'Độ nổi tiếng hiện tại của IDOL',
    onair: 'Đang phát trực tuyến',
    isValid: 'Trạng thái',
    createdAt: 'Thời gian được tạo',
    option: 'Thao tác',

    familyInfo: 'Thông tin của tộc',
    familyName: 'Tên của tộc',
    bindEmail: 'Xác thực EMAIL',
    memberCount: 'Số lượng IDOL LIVE',
    memberList: 'Danh sách IDOL',
  },
  Sports: {
    myEvents: '我的赛事',
    sportsEvents: '赛事列表',
    date: '日期',
    leagueName: '联盟',
    eventName: '赛事名称',
    liveStatus: '是否开播',
    customerCount: '预约观众数',
    action: '操作',
    cancelEvent: '确认取消预约赛事?',
    fllowEvent: '确认预约此场赛事?',
    ok: '确认',
    cancel: '取消',
    cancelEventSuccess: '取消预约成功',
    fllowEventSuccess: '预约成功',
    fllowed: '已预约',
    fllow: '预约',
    onair: '开播中',
    over: '已开播',
    never: '未开播',
    home: '(主)',
    guest: '(客)',
    region: '地区',
    allLeague: '全部联盟',
    allRegion: '全部地区',
    fllowedAnchor: '已预约主播数',
    fllowEventError: '此场赛事主播数已额满',
    fllowFull: '预约已满',
  },
  SportLive: {
    main: '(主)',
    guest: '(客)',
    selectEvent: '选择直播赛事',
    seletcEventLimitNotice: '* 仅显示未来 12 小时内已预约的赛事',
    liveEvent: '直播赛事',
    streamUrl: '串流网址',
    streamKey: '串流金钥',
    tooltip: '温馨提示：用OBS助手等工具，配置推流地址后在助手上开始推流后，再点击下方的“开始”按钮',
    startLive: '开始直播',
    stopLive: '关闭直播',
    screenSwitch: '视频显示',
    alartMessage: "未发现到OBS助手等工具推流数据，请重新尝试推流再开启'视频显示'。",
    twoHours: '2 小时',
    oneDay: '1 天',
    threeDay: '3 天',
    aMonth: '30 天',
    infinite: '永久',
    becomeManager: '已成为房管',
    cancelledManager: '已取消房管',
    has: '已将',
    mute: '禁言',
    addManager: '设定为房管',
    delManager: '移除房管',
    manager: '房管',
    muteList: '禁言名单',
    other: 'khác',
    customTitle: 'Vui lòng nhập tiêu đề trực tiếp tùy chỉnh',
    customMessage: 'Vui lòng nhập tên tiêu đề'
  },
  MuteList: {
    name: 'Biệt danh',
    muteTime: 'Thời gian bị hạn chế',
    timeLeft: 'Thời gian còn lại',
    end: 'Kết thúc',
    manager: 'Quản lý phòng',
    action: 'Thao tác',
    has: 'Đã được',
    disMute: 'Hủy bỏ im lặng'
  },
  SportStatistics: {
    liveStartAt: '直播开始时间',
    liveOverAt: '直播结束时间',
    startAt: '开赛日期',
    eventName: '赛事名称',
    gamePrizeSum: '费用',
    gameGiftSum: '送礼金额',
    main: '(主)',
    guest: '(客)',
  },
  SportUserInfo: {
    setting: '基本设置',
    background: '背景图设置',
    private: '安全设置'
  },
  SportPrivateSetting: {
    account: '帐户密码'
  },
  SportUserSetting: {
    name: '名称',
    email: '信箱',
    introduction: '自我介绍',
    announcement: '主播公告',
    uploadData: '更新基本讯息',
    uploadAvatar: '更换头像'
  },
  SportCommonTypes: {
    Soccer: '足球',
    Basketball: '篮球',
    Tennis: '网球',
    Volleyball: '排球',
    Baseball: '棒球',
    AmericanFootball: '美式足球',
    IceHockey: '冰上曲棍球',
    ESports: '电子竞技',
    Handball: '手球',
    Snooker: '台球',
    Badminton: '羽球',
    TableTennis: '桌球',
    Boxing: '拳击',
    WaterPolo: '水球',
    Floorball: '地板球',
    Squash: '壁球',
    Cricket: '板球',
    RugbyUnion: '联合式橄榄球',
    RugbyLeague: '联盟式橄榄球',
    Darts: '飞镖',
    Futsal: '室内五人足球',
    BeachVolleyball: '沙滩排球',

    Others: '其它'
  }
}

export default i18nObject(language)