import React, { Component } from 'react'
import { Button, Avatar, List, Tag } from 'antd'
import { injectIntl } from 'react-intl'

class Lottery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null
    }
  }

  onClick = (name, gameId, gameCode, gameIcon) => {
    this.setState({ selected: gameId })
    this.props.onGameSelected({
      type: 'lotto',
      name, gameId, gameCode, gameIcon
    })
  }

  renderBindGameButton = (selectedGameId, gameId, bindGameId, onGameConfirm, onGameCancel) => {
    if (selectedGameId !== gameId) return (null)
    if (selectedGameId == bindGameId) {
      return (
        <Button
          type="primary"
          danger
          onClick={() => onGameCancel()}>取消綁定</Button>
      )
    } else {
      return (
        <Button
          type="primary"
          onClick={() => onGameConfirm()}>綁定</Button>
      )
    }
  }

  render() {
    const { games, bindGameId, onGameConfirm, onGameCancel } = this.props

    return (
      <div
        id="scrollableDiv"
        style={{
          height: 300,
          width: '100%',
          overflow: 'auto',
          border: '1px solid rgba(140, 140, 140, 0.35)',
        }}
      >
        <List
          header={
            <div style={{ fontWeight: 'bold' }}>
              遊戲選單
            </div>
          }
          bordered
          itemLayout="horizontal"
          dataSource={games}
          renderItem={(game) => (
            <List.Item
              style={{ backgroundColor: 'white' }}
              className={`gamelist ${this.state.selected == game.gameId}`}
              onClick={() => this.onClick(game.name, game.gameId, game.gameCode, game.image)}
            >
              <List.Item.Meta
                avatar={<Avatar src={game.image} />}
                title={game.name}
                description={bindGameId == game.gameId ? <Tag color="#2db7f5">綁定中</Tag> : ""}
              />
              {this.renderBindGameButton(this.state.selected, game.gameId, bindGameId, onGameConfirm, onGameCancel)}
            </List.Item>
          )}
        />
      </div>

    )
  }
}

export default injectIntl(Lottery)