import { i18nObject } from '../helper/helper'

const language = {
  app: {
    mobileLogin: 'Mobile Login',
    logout: 'Logout',
    qrcodeDesc: 'Scan QRCode using Streaming APP'
  },
  familyMonitor: {
    anchor_count: 'Anchor Count',
    anchor_live_count: 'Live Count',
    anchor_porn_live_count: 'Porn Live Count',
    clientCount: 'Client Count',
    no_anchor: 'No Anchor',
    sureRemove: 'Are you sure offline?',
    cancel: 'Cancel',
    confirm: 'Confirm',
    remove: 'Offline',
    monitor_live: 'Monitor Live',
    sendGift: 'Send Anchor',
    monitor: 'Monitor',
    enterRoom: 'Enter Room',
    anchor_live: 'Live',
    anchor_porn_live: 'Pron Live',
    inputPlaceholder: 'Private messages from monitors to streamers...',
    send: 'Send',
    kick_success: 'The operation is successful, update the host list',
  },
  userInfo: {
    icon: 'Photo',
    backgroundImage: 'Background Photo',
    name: 'Name',
    name_en: 'English Name',
    email: 'Email',
    password: 'Password',
    birthday: 'Birthday',
    height: 'Height',
    weight: 'Weight',
    width: 'Sanwei',
    blood: 'Blood Type',
    constellation: 'Constellation',
    language: 'Language',
    education: 'Education',
    job: 'Job',
    interest: 'Interest',
    movie: 'Favorite Movie',
    food: 'Favorite Food',
    actor: 'Favorite Atist',
    game: 'Favorite Game',
    number: 'Lucky Number',
    introduction: 'Introduction',
    modifyPassword: 'Modify password',
    modify: 'Modify'
  },
  routes: {
    info: 'Infomation',
    dashboard: 'Dashboard',
    liveControl: 'Live Control',
    giftHistory: 'Gift History',
    liveHistory: 'Live History',
    modelPlayer: 'Anchor Streaming',
    RoomOrder: 'Room Order',
    analysis: 'Live Analysis',
    userIncome: 'Income History',
    familyManager: 'Family Manager',
    familyIncome: 'Family Income',
    events: 'Sports Events',
    SportStatistics: 'Sport Statistics',
    monitor_live: 'Monitor Live'
  },
  global: {
    update: 'Update',
    query: 'Query',
    dateRange: 'Date Ranges',
    all: 'All',
    export: 'Export'
  },
  dateTimePicker: {
    today: 'Today',
    yesterday: 'Yesterday',
    l7days: 'Last 7 days',
    l30days: 'Last 30 days',
    tMonth: 'This Month',
    lMonth: 'Last Month',
    custom: 'Custom Range',
  },
  lasSocket: {
    disconnect: 'Disconnect',
    connect: 'Connect',
    loginFailed: 'Login Failed'
  },
  login: {
    title: 'Anchor Login',
    account: 'Account',
    accountPlaceholder: 'Email',
    password: 'Password',
    modelLogin: 'Anchor Login',
    adminLogin: 'Admin Login',
    familyLogin: 'Family Login',
    adminPlaceholder: 'Admin Account',
  },
  giftHistory: {
    model: 'Anchor Name',
    fromName: 'User Account',
    giftInfo: 'gift info',
    amount: 'amount',
    createdAt: 'Created Date',
    sum: 'Gift Sum'
  },
  liveHistory: {
    createdAt: 'Start',
    updatedAt: 'End',
    total: 'Total',
    type: 'Type',
    all: 'All',
    type0: 'Room',
    type1: 'Mobile'
  },
  liveAnalysis: {
    date: 'Date',
    liveCount: 'Live Counts',
    totalInterval: 'Interval',
    watchedCounts: 'Watched Counts',
    clientCounts: 'Client Counts',
    giftSum: 'Gift Sum',
    betCount: 'Bet Counts',
    winOrLose: 'Win/Lose',
    eBet: 'E Bet',
    eBetWin: 'E Bet Win',
    liveBet: 'Live Bet',
    liveValidBet: 'Live Valid Bet',
    liveWin: 'Live Win',
    lotteryBet: 'Lottery Bet',
    lotteryWin: 'Lottery Win',
    battleLostSum: 'Battle Lost Sum',
    battleValidBet: 'Battle Valid Bet',
    battleWinSum: 'Battle Win Sum',
    fishBet: 'Fish Bet',
    fishWin: 'Fish Win',
    sportBet: 'Sport Bet',
    sportValidBet: 'Sport Valid Bet',
    sportWin: 'Sport Win',
    handicapBet: 'Handicap Bet',
    handicapWin: 'Handicap Win',
    statisticTime: 'Date Ranges',
    model: 'Model Name',
    privateFees: 'Private Fees',
    totalGiftSum: 'Total Gift Sum',
    totalPrivateFees: 'Total Private Fees',
  },
  chat: {
    gift: 'give you',
    heart: 'give a heart',
    monitor: 'Manager PM',
    model: 'Anchor',
    betting: 'bet',
    win: 'win',
    placeholder: 'Say something...',
    send: 'send',
    enter: 'enter',
    focus: 'Follow the anchor',
    manager: 'House management'
  },
  games: {
    series: '{series} times',
    whichSeries: 'Series: {series}',
    lottery: 'Lottery',
    esport: 'E-Sport',
    sport: 'Sport',
    littleGame: 'little Game',
    comingSoon: 'Coming soon...',
    gameSelected: 'Selected',
    gameRecommend: 'Send',
    gameCancel: 'Cancel',
  },
  liveControl: {
    scheduledAt: 'will start at',
    webStart: 'ON Line',
    webEnd: 'OFF Line',
    mobile: 'Mobile APP',
    PC: 'OBS',
    PCStream: 'OBS Tool',
    PCStreaming: 'OBS Tool - Streaming',
    pushUrl: 'Stream URI',
    pushKey: 'Stream Key',
    OBSContent: 'Warm reminder: Use tools such as OBS assistant to configure the streaming address and start streaming on the assistant, then click the "Start" button below',
    loginQRCode: 'Click to get the login QR code',
    onAir: 'OnAir',
    selectDevice: 'Please select a live device',
    normal: 'Free',
    vip: 'VIP',
    private: 'Charge',
    onAirType: 'Type',
    roomLevel: 'Level',
    roomWatch: 'Duration',
    roomAmount: 'Admission',
    min: 'min',
    unrestricted: 'Unrestricted',
    qrcodeMark: 'The QR code is valid within 5 minutes, please re-acquire it when it expires.'
  },
  RoomOrder: {
    customerName: 'User Account',
    createdAt: 'Created Date',
    fee: 'Fee',
    minute: 'Minutes',
  },
  UserIncome: {
    Date: 'Date',
    onairDuration: 'Onair Duration',
    Wage: 'Wage',
    giftSplite: 'Gift (%)',
    roomFeeSplite: 'Room Fee(%)',
    giftIncome: 'Gift Income',
    roomFeeIncome: 'RoomFee Income',
    onairIncome: 'Onair Income',
    totalGiftIncome: 'Total Gift',
    totalRoomFeeIncome: 'Total RoomFee',
    totalIncome: 'Total Income'
  },
  FamilyIncome: {
    Date: 'Date',
    users: 'Number of anchors',
    Wage: 'Hourly wage',
    giftSplite: 'Gift share (%)',
    roomFeeSplite: 'Room fee share (%)',
    onairDuration: 'Onair Duration',
    Income: 'Family Earnings',
    amount: 'Settlement amount',
    amount_na: 'Unsettled amount',
    endDate: 'Settlement time',
    option: 'Operation',
    memberProfit: 'MemberProfit',
    detail: 'Detail',
    sum: 'Sum',
  },
  UserDetails: {
    userId: 'Anchor ID',
    familyName: 'Family Name',
    userName: 'Anchor name',
    Wage: 'Hourly wage',
    giftSplite: 'Gift share (%)',
    roomFeeSplite: 'Room fee share (%)',
    onairDuration: 'Onair Duration',
    giftIncome: 'gift income',
    roomFeeIncome: 'Room Fee Income',
    onairIncome: 'Start-up income',
    totalGiftIncome: 'Gift Income',
    totalRoomFeeIncome: 'Room fee income',
    totalIncome: 'Anchor Income',

    total: 'Total',
  },
  PopModifyPassword: {
    modifyPassword: 'Modify',
    cancel: 'Cancel',
    newPassword: 'New password',
    chackPassword: 'New password again',
    success: 'Modified successfully',

    chackFailure: 'The two input passwords do not match',
    inputPassword: '6~10 codes and at least one English character.'
  },

  LineChart: {
    date: 'Date',
    points: 'Points',
  },
  FamilyInfo: {
    modelName: 'Name',
    modelEmail: 'Email',
    hourlyRate: 'HourlyRate',
    giftAmountRate: 'Gift Rate (%)',
    privateRoomFeeRate: 'Fee Rate (%)',
    popular: 'Popular',
    onair: 'OnAir',
    isValid: 'Valid',
    createdAt: 'Created At',
    option: 'Option',

    familyInfo: 'Infomation',
    familyName: 'Name',
    bindEmail: 'Email',
    memberCount: 'Member Counts',
    memberList: 'Member List',
  },
  Sports: {
    myEvents: 'My Events',
    sportsEvents: 'List of events',
    date: 'Date',
    leagueName: 'League',
    eventName: 'event name',
    liveStatus: 'Whether to start broadcasting',
    customerCount: 'Number of reserved audience',
    action: 'Operation',
    cancelEvent: 'Are you sure to cancel the scheduled event?',
    fllowEvent: 'Confirm to reserve this event?',
    ok: 'OK',
    cancel: 'Cancel',
    cancelEventSuccess: 'Cancel the appointment successfully',
    fllowEventSuccess: 'Appointment successful',
    fllowed: 'appointed',
    fllow: 'Appoint',
    onair: 'Starting broadcast',
    over: 'Already broadcast',
    never: 'No broadcast',
    home: '(Main)',
    guest: '(Guest)',
    region: 'Region',
    allLeague: 'All Leagues',
    allRegion: 'All Regions',
    fllowedAnchor: 'Number of scheduled anchors',
    fllowEventError: 'The number of hosts for this event is full',
    fllowFull: 'The appointment is full',
  },
  SportLive: {
    main: '(Main)',
    guest: '(Guest)',
    selectEvent: 'Select live events',
    seletcEventLimitNotice: '* Only visiabled events have been reserved in the next 12 hours',
    liveEvent: 'Live Event',
    streamUrl: 'Streaming URL',
    streamKey: 'Stream Key',
    tooltip: 'Reminder: Use OBS assistant and other tools, configure the push address and start pushing on the assistant, and then click the "start" button below',
    startLive: 'Start live broadcast',
    stopLive: 'Close the live broadcast',
    screenSwitch: 'Video display',
    alartMessage: "The data pushed by tools such as OBS assistant is not detected. Please try to push again and turn on'Video Display'.",
    twoHours: '2 hours',
    oneDay: '1 day',
    threeDay: '3 days',
    aMonth: '30 days',
    infinite: 'permanent',
    becomeManager: 'Has become a house manager',
    cancelledManager: 'Room management has been cancelled',
    has: 'has been',
    mute: 'Mute',
    addManager: 'Set as room management',
    delManager: 'Remove room management',
    manager: 'Management',
    muteList: 'Mute List',
    other: 'other',
    customTitle: 'Please enter a custom live title',
    customMessage: 'Please enter a title name'
  },
  MuteList: {
    name: 'nickname',
    muteTime: 'Mute time',
    timeLeft: 'Remaining time',
    end: 'Ended',
    manager: 'House management',
    action: 'Operation',
    has: 'has been',
    disMute: 'Unmute'
  },
  SportStatistics: {
    liveStartAt: 'Live start time',
    liveOverAt: 'Live broadcast end time',
    startAt: 'Start date',
    eventName: 'event name',
    gamePrizeSum: 'cost',
    gameGiftSum: 'Gift Amount',
    main: '(Main)',
    guest: '(Guest)',
  },
  SportUserInfo: {
    setting: 'Basic settings',
    background: 'Background settings',
    private: 'Security Settings'
  },
  SportPrivateSetting: {
    account: 'Account password'
  },
  SportUserSetting: {
    name: 'Name',
    email: 'Email',
    introduction: 'Self introduction',
    announcement: 'Anchor announcement',
    uploadData: 'Update basic information',
    uploadAvatar: 'Change Avatar'
  },
  SportCommonTypes: {
    Soccer: 'Soccer',
    Basketball: 'Basketball',
    Tennis: 'Tennis',
    Volleyball: 'Volleyball',
    Baseball: 'Baseball',
    AmericanFootball: 'American Football',
    IceHockey: 'Ice Hockey',
    ESports: 'ESports',
    Handball: 'Handball',
    Snooker: 'Snooker',
    Badminton: 'Badminton',
    TableTennis: 'Table Tennis',
    Boxing: 'Boxing',
    WaterPolo: 'Water Polo',
    Floorball: 'Floorball',
    Squash: 'Squash',
    Cricket: 'Cricket',
    RugbyUnion: 'Rugby Union',
    RugbyLeague: 'Rugby League',
    Darts: 'Darts',
    Futsal: 'Futsal',
    BeachVolleyball: 'Beach Volleyball',

    Others: 'Others'
  }
}

export default i18nObject(language)