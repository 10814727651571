import { i18nObject } from '../helper/helper'

const language = {
  app: {
    mobileLogin: '手机登入',
    logout: '登出',
    qrcodeDesc: '请打开手机APP扫码登入'
  },
  familyMonitor: {
    anchor_count: '目前主播人数',
    anchor_live_count: '「绿播」主播人数',
    anchor_porn_live_count: '「色播」主播人数',
    clientCount: '观看直播总人数',
    no_anchor: '无主播直播中',
    sureRemove: '确定踢下线?',
    cancel: '取消',
    confirm: '确定',
    remove: '踢下线',
    monitor_live: '监控直播',
    sendGift: '送给主播',
    monitor: '监控人员',
    enterRoom: '进入房间',
    anchor_live: '录播',
    anchor_porn_live: '色播',
    inputPlaceholder: '监控人员给主播的私密讯息...',
    send: '送出',
    kick_success: '操作成功, 更新主播列表',
  },
  userInfo: {
    icon: '大头照',
    backgroundImage: '背景图',
    name: '主播名称',
    name_en: '主播英文名',
    email: '主播邮箱',
    password: '密码',
    birthday: '生日',
    height: '身高',
    weight: '体重',
    width: '三围',
    blood: '血型',
    constellation: '星座',
    language: '语言',
    education: '学历',
    job: '工作经验',
    interest: '兴趣',
    movie: '最喜欢的电影',
    food: '最喜欢的食物',
    actor: '最喜欢的艺人',
    game: '最喜欢的游戏',
    number: '幸运数字',
    introduction: '自我介绍',
    modifyPassword: '修改密码',
    modify: '修改'
  },
  routes: {
    info: '個人資料',
    dashboard: '仪表板',
    liveControl: '直播管理',
    giftHistory: '打赏纪录',
    liveHistory: '开播纪录',
    modelPlayer: '全部直播浏览',
    RoomOrder: '房费纪录',
    analysis: '开播数据分析',
    userIncome: '主播收益记录',
    familyManager: '家族管理',
    familyIncome: '家族收益纪录',
    events: '赛事管理',
    SportStatistics: '查询赛事开播统计',
    monitor_live: '监控直播'
  },
  global: {
    update: '更新',
    query: '查询',
    dateRange: '日期区间',
    all: '全部',
    export: '汇出'
  },
  dateTimePicker: {
    today: '今天',
    yesterday: '昨天',
    l7days: '最近七天',
    l30days: '最近三十天',
    tMonth: '这个月',
    lMonth: '上个月',
    custom: '自订区间',
  },
  lasSocket: {
    disconnect: '已中断连线',
    connect: '已连线',
    loginFailed: '登入失败'
  },
  login: {
    title: '主播登入',
    account: '帐号',
    accountPlaceholder: 'Email',
    password: '密码',
    modelLogin: '主播登入',
    adminLogin: '管理员登入',
    familyLogin: '家族登入',
    adminPlaceholder: '管理员帐号',
  },
  giftHistory: {
    model: '主播名称',
    fromName: '打赏帐号',
    giftInfo: '礼物',
    amount: '总额',
    createdAt: '日期',
    sum: '打赏总和'
  },
  liveHistory: {
    createdAt: '开始时间',
    updatedAt: '结束时间',
    total: '总和',
    type: '类型',
    all: '全部',
    type0: '直播室',
    type1: '手机推流'
  },
  liveAnalysis: {
    date: '日期',
    liveCount: '开播次数',
    totalInterval: '开播时长',
    watchedCounts: '观看次数',
    clientCounts: '观看人数',
    giftSum: '礼物金额',
    betCount: '投注人数',
    winOrLose: '玩家盈亏',
    eBet: '电子投注',
    eBetWin: '电子中奖',
    liveBet: '真人投注',
    liveValidBet: '真人有效投注',
    liveWin: '真人中奖',
    lotteryBet: '彩票投注',
    lotteryWin: '彩票中奖',
    battleLostSum: '对战输额',
    battleValidBet: '对战有效投注',
    battleWinSum: '对战赢额',
    fishBet: '捕鱼投注',
    fishWin: '捕鱼中奖',
    sportBet: '体育投注',
    sportValidBet: '体育有效投注',
    sportWin: '体育中奖',
    handicapBet: '盘口彩票投注',
    handicapWin: '盘口彩票中奖',
    statisticTime: '统计时间',
    model: '主播名称',
    privateFees: '房费',
    totalGiftSum: '礼物金额总计',
    totalPrivateFees: '房费总计',
  },
  chat: {
    gift: '送给你',
    heart: '赠送爱心',
    monitor: '监控人员',
    model: '主播',
    placeholder: '说点什么...',
    betting: '投注了',
    win: '中奖了',
    send: '送出',
    enter: '进入房间',
    focus: '已关注主播',
    manager: '房管'
  },
  games: {
    series: '{series} 期',
    whichSeries: '第 {series} 期',
    lottery: '彩票',
    esport: '电竞',
    sport: '体育',
    littleGame: '小游戏',
    comingSoon: '敬请期待',
    gameSelected: '目前所选游戏',
    gameRecommend: '确定发送推荐',
    gameCancel: '取消推荐',
  },
  liveControl: {
    scheduledAt: '预计开播时间',
    webStart: '开启直播室',
    webEnd: '关闭直播室',
    mobile: '手机 APP',
    PC: 'OBS 助手',
    PCStream: 'PC推流工具',
    PCStreaming: 'PC推流工具 - 推流中',
    pushUrl: '串流网址',
    pushKey: '串流金钥',
    OBSContent: '温馨提示：用OBS助手等工具，配置推流地址后在助手上开始推流后，再点击下方的“开始”按钮',
    loginQRCode: '点击获取登录二维码',
    onAir: '开始直播',
    selectDevice: '请选择直播设备',
    normal: '绿播',
    vip: '大秀',
    private: '私密收费',
    onAirType: '开房类型',
    roomLevel: '入房等级',
    roomWatch: '观看时间',
    roomAmount: '入房金额',
    min: '分',
    unrestricted: '不限',
    qrcodeMark: '该二维码5分钟内有效，过期请重新获取'
  },
  RoomOrder: {
    customerName: '用户名',
    createdAt: '交易时间',
    fee: '金额',
    minute: '时数 (分)',
  },
  UserIncome: {
    Date: '日期',
    onairDuration: '开播时长',
    Wage: '时薪',
    giftSplite: '礼物分成(%)',
    roomFeeSplite: '房费分成(%)',
    giftIncome: '礼物收入',
    roomFeeIncome: '房费收入',
    onairIncome: '开播收益',
    totalGiftIncome: '礼物收益',
    totalRoomFeeIncome: '房费收益',
    totalIncome: '收益小结'
  },
  FamilyIncome: {
    Date: '日期',
    users: '主播数',
    Wage: '时薪',
    giftSplite: '礼物分成(%)',
    roomFeeSplite: '房费分成(%)',
    onairDuration: '开播时长',
    Income: '家族收益',
    amount: '结算金额',
    amount_na: '未结算金额',
    endDate: '结算时间',
    option: '操作',
    memberProfit: '主播收益支出',
    detail: '主播明細',
    sum: '总计'
  },
  UserDetails: {
    userId: '主播 ID',
    familyName: '家族名称',
    userName: '主播名称',
    Wage: '时薪',
    giftSplite: '礼物分成(%)',
    roomFeeSplite: '房费分成(%)',
    onairDuration: '开播时长',
    giftIncome: '礼物收入',
    roomFeeIncome: '房费收入',
    onairIncome: '开播收益',
    totalGiftIncome: '礼物收益',
    totalRoomFeeIncome: '房费收益',
    totalIncome: '主播收益',

    total: '总计',
  },
  PopModifyPassword: {
    modifyPassword: '修改密码',
    cancel: '取消',
    newPassword: '输入新密码',
    chackPassword: '再次输入新密码',
    success: '修改成功',

    chackFailure: '两次输入密码不相符',
    inputPassword: '6~10码且至少包含一个英文字符'
  },

  LineChart: {
    date: '日期',
    points: '点数',
  },

  FamilyInfo: {
    modelName: '主播名称',
    modelEmail: '主播邮箱',
    hourlyRate: '时薪',
    giftAmountRate: '礼物分成 (%)',
    privateRoomFeeRate: '房费分成 (%)',
    popular: '当前主播人气',
    onair: '直播中',
    isValid: '状态',
    createdAt: '创建时间',
    option: '操作',

    familyInfo: '家族資訊',
    familyName: '家族名称',
    bindEmail: '绑定邮箱',
    memberCount: '主播数',
    memberList: '主播列表',
  },
  Sports: {
    myEvents: '我的赛事',
    sportsEvents: '赛事列表',
    date: '日期',
    leagueName: '联盟',
    eventName: '赛事名称',
    liveStatus: '是否开播',
    customerCount: '预约观众数',
    action: '操作',
    cancelEvent: '确认取消预约赛事?',
    fllowEvent: '确认预约此场赛事?',
    ok: '确认',
    cancel: '取消',
    cancelEventSuccess: '取消预约成功',
    fllowEventSuccess: '预约成功',
    fllowed: '已预约',
    fllow: '预约',
    onair: '开播中',
    over: '已开播',
    never: '未开播',
    home: '(主)',
    guest: '(客)',
    region: '地区',
    allLeague: '全部联盟',
    allRegion: '全部地区',
    fllowedAnchor: '已预约主播数',
    fllowEventError: '此场赛事主播数已额满',
    fllowFull: '预约已满',
  },
  SportLive: {
    main: '(主)',
    guest: '(客)',
    selectEvent: '选择直播赛事',
    seletcEventLimitNotice: '* 仅显示未来 12 小时内已预约的赛事',
    liveEvent: '直播赛事',
    streamUrl: '串流网址',
    streamKey: '串流金钥',
    tooltip: '温馨提示：用OBS助手等工具，配置推流地址后在助手上开始推流后，再点击下方的“开始”按钮',
    startLive: '开始直播',
    stopLive: '关闭直播',
    screenSwitch: '视频显示',
    alartMessage: "未发现到OBS助手等工具推流数据，请重新尝试推流再开启'视频显示'。",
    twoHours: '2 小时',
    oneDay: '1 天',
    threeDay: '3 天',
    aMonth: '30 天',
    infinite: '永久',
    becomeManager: '已成为房管',
    cancelledManager: '已取消房管',
    has: '已将',
    mute: '禁言',
    addManager: '设定为房管',
    delManager: '移除房管',
    manager: '房管',
    muteList: '禁言名单',
    other: '其他',
    customTitle: '请输入自定义直播标题',
    customMessage: '请输入标题名称'
  },
  MuteList: {
    name: '昵称',
    muteTime: '被禁言时间',
    timeLeft: '剩余时间',
    end: '已结束',
    manager: '房管',
    action: '操作',
    has: '已将',
    disMute: '解除禁言'
  },
  SportStatistics: {
    liveStartAt: '直播开始时间',
    liveOverAt: '直播结束时间',
    startAt: '开赛日期',
    eventName: '赛事名称',
    gamePrizeSum: '费用',
    gameGiftSum: '送礼金额',
    main: '(主)',
    guest: '(客)',
  },
  SportUserInfo: {
    setting: '基本设置',
    background: '背景图设置',
    private: '安全设置'
  },
  SportPrivateSetting: {
    account: '帐户密码'
  },
  SportUserSetting: {
    name: '名称',
    email: '信箱',
    introduction: '自我介绍',
    announcement: '主播公告',
    uploadData: '更新基本讯息',
    uploadAvatar: '更换头像'
  },
  SportCommonTypes: {
    Soccer: '足球',
    Basketball: '篮球',
    Tennis: '网球',
    Volleyball: '排球',
    Baseball: '棒球',
    AmericanFootball: '美式足球',
    IceHockey: '冰上曲棍球',
    ESports: '电子竞技',
    Handball: '手球',
    Snooker: '台球',
    Badminton: '羽球',
    TableTennis: '桌球',
    Boxing: '拳击',
    WaterPolo: '水球',
    Floorball: '地板球',
    Squash: '壁球',
    Cricket: '板球',
    RugbyUnion: '联合式橄榄球',
    RugbyLeague: '联盟式橄榄球',
    Darts: '飞镖',
    Futsal: '室内五人足球',
    BeachVolleyball: '沙滩排球',

    Others: '其它'
  }
}

export default i18nObject(language)